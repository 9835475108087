body {
  font-family: 'Lato', sans-serif; }
  body .grecaptcha-badge {
    visibility: hidden; }

ul {
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none; }

a {
  color: #231f20;
  outline: 0; }
  a:hover, a:focus {
    text-decoration: underline;
    color: #231f20; }

h1 {
  font-size: calc(28px + 10 * ( (100vw - 320px) / 1600)); }
  @media screen and (max-width: 320px) {
    h1 {
      font-size: 28px; } }
  @media screen and (min-width: 1920px) {
    h1 {
      font-size: 38px; } }

h2 {
  font-size: calc(26px + 6 * ( (100vw - 320px) / 1600)); }
  @media screen and (max-width: 320px) {
    h2 {
      font-size: 26px; } }
  @media screen and (min-width: 1920px) {
    h2 {
      font-size: 32px; } }

h3 {
  font-size: calc(24px + 4 * ( (100vw - 320px) / 1600)); }
  @media screen and (max-width: 320px) {
    h3 {
      font-size: 24px; } }
  @media screen and (min-width: 1920px) {
    h3 {
      font-size: 28px; } }

h4 {
  font-size: calc(21px + 3 * ( (100vw - 320px) / 1600)); }
  @media screen and (max-width: 320px) {
    h4 {
      font-size: 21px; } }
  @media screen and (min-width: 1920px) {
    h4 {
      font-size: 24px; } }

h5 {
  font-size: calc(18px + 2 * ( (100vw - 320px) / 1600)); }
  @media screen and (max-width: 320px) {
    h5 {
      font-size: 18px; } }
  @media screen and (min-width: 1920px) {
    h5 {
      font-size: 20px; } }

p {
  font-size: calc(14px + 2 * ( (100vw - 320px) / 1600)); }
  @media screen and (max-width: 320px) {
    p {
      font-size: 14px; } }
  @media screen and (min-width: 1920px) {
    p {
      font-size: 16px; } }

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-display: fallback;
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), local("Lato-Light"), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh7USSwaPGR_p.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Lato';
  font-display: fallback;
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), local("Lato-Light"), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh7USSwiPGQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-display: fallback;
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"), url(https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjxAwXjeu.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Lato';
  font-display: fallback;
  font-style: normal;
  font-weight: 400;
  src: local("Lato Regular"), local("Lato-Regular"), url(https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjx4wXg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-display: fallback;
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), local("Lato-Bold"), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh6UVSwaPGR_p.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Lato';
  font-display: fallback;
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), local("Lato-Bold"), url(https://fonts.gstatic.com/s/lato/v14/S6u9w4BMUTPHh6UVSwiPGQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }
